import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { apiServices } from './services/apiServices';

const rootReducer = combineReducers({
  [apiServices.reducerPath]: apiServices.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiServices.middleware),
});