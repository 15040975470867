import { DotLoader } from "react-spinners";

export const Loader = () => {
    return (
        <>
            <div className='vh-100 d-flex align-items-center justify-content-center'>
                <DotLoader size={100} color="#001529" />
            </div>
        </>
    )
};


