import { lazy, Suspense } from "react";
import { Loader } from "./utils/Loader";

const App = () => {
  const AppRouter = lazy(() => import("./routes/AdminRoute"));

  return (
    <Suspense fallback={<Loader />}>
      <AppRouter />
    </Suspense>
  )
}

export default App;

