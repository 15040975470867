import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '../../utils/Endpoint';
import { METHODS } from '../../Constant';
import MyToast from '../../utils/MyToast';
const base_url = process.env.REACT_APP_BASE_URL;

const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: base_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  });

  const result = await rawBaseQuery(args, api, extraOptions);
  if (result.error) {
    const { status, data } = result.error;

    if (status === 400 || status === 417) {
      MyToast("e", data?.message);
    }

    // Handle unauthorized errors (Token is invalid)
    else if (status === 401 || status === 403) {
      localStorage.clear();
      window.location.href = "/"
    }

    return false;
  } else {
    return result;
  }
};

export const apiServices = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithErrorHandling,

  endpoints: (builder) => ({
    // **************************************************************** Auth ****************************************************************
    login: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTH.LOGIN,
        method: METHODS.POST,
        body: data
      })
    }),
    forgot: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTH.FORGOT,
        method: METHODS.POST,
        body: data
      })
    }),
    verify: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTH.VERIFY_OTP,
        method: METHODS.POST,
        body: data
      })
    }),
    reset: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTH.RESET,
        method: METHODS.POST,
        body: data
      })
    }),

    // **************************************************************** TAX BRACKETS ****************************************************************
    // State Bracket
    stateBracketAdd: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STATE_BRACKET.StateBracketAdd,
        method: METHODS.POST,
        body: data
      })
    }),
    stateBracketList: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STATE_BRACKET.StateBracketList,
        method: METHODS.POST,
        body: data
      })
    }),
    stateBracketUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STATE_BRACKET.StateBracketUpdate,
        method: METHODS.PUT,
        body: data
      })
    }),
    stateBracketDelete: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STATE_BRACKET.StateBracketDelete,
        method: METHODS.DELETE,
        body: data
      })
    }),

    // Federal Bracket
    federalBracketAdd: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.FEDERAL_BRACKET.FederalBracketAdd,
        method: METHODS.POST,
        body: data
      })
    }),
    federalBracketList: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.FEDERAL_BRACKET.FederalBracketList,
        method: METHODS.POST,
        body: data
      })
    }),
    federalBracketUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.FEDERAL_BRACKET.FederalBracketUpdate,
        method: METHODS.PUT,
        body: data
      })
    }),
    federalBracketDelete: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.FEDERAL_BRACKET.FederalBracketDelete,
        method: METHODS.DELETE,
        body: data
      })
    }),

    // **************************************************************** Variable ****************************************************************
    // Variable
    strategyVariable: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.VARIABLE.StrategyVariableList,
        method: METHODS.POST,
        body: data
      })
    }),
    strategyVariableUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.VARIABLE.StrategyVariableUpdate,
        method: METHODS.PUT,
        body: data
      })
    }),

    // Fee
    strategyVariableFeeList: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.VARIABLE.StandardFeeList,
        method: METHODS.POST,
        body: data
      })
    }),
    strategyVariableFeeUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.VARIABLE.StandardFeeUpdate,
        method: METHODS.PUT,
        body: data
      })
    }),

    // Deduction
    strategyVariableDeductionList: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.VARIABLE.StandardDeductionList,
        method: METHODS.POST,
        body: data
      })
    }),
    strategyVariableDeductionAdd: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.VARIABLE.StandardDeductionAdd,
        method: METHODS.POST,
        body: data
      })
    }),
    strategyVariableDeductionUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.VARIABLE.StandardDeductionUpdate,
        method: METHODS.PUT,
        body: data
      })
    }),

    // Child
    strategyChildList: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.VARIABLE.StandardChildList,
        method: METHODS.POST,
        body: data
      })
    }),
    strategyChildAdd: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.VARIABLE.StandardChildAdd,
        method: METHODS.POST,
        body: data
      })
    }),
    strategyChildUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.VARIABLE.StandardChildUpdate,
        method: METHODS.PUT,
        body: data
      })
    }),

    // **************************************************************** strategy ****************************************************************
    strategyMainList: builder.query({
      query: ({ page, limit }) => ({
        url: `${apiEndpoints.AUTHORIZED.STRATEGY.StrategyMainList}?page=${page}&limit=${limit}`,
        method: METHODS.GET
      }),
      providesTags: ['StrategyMainList'],
    }),
    strategyMainView: builder.query({
      query: ({ rule_type }) => ({
        url: `${apiEndpoints.AUTHORIZED.STRATEGY.StrategyMainDetail}?rule_type=${rule_type}`,
        method: METHODS.GET
      }),
    }),
    spFeeListDetail: builder.query({
      query: ({sp_id}) => ({
        url: `${apiEndpoints.AUTHORIZED.STRATEGY.SPFeeList}?strategy_entity=for_sp&sp_id=${sp_id}`,
        method: METHODS.GET,
      }),
      providesTags: ['sp-fee-list'],
    }),
    strategyUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STRATEGY.StrategyMainListUpdate,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['StrategyMainList'],
    }),
    strategyViewStatusUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STRATEGY.StrategyUpdateStatus,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['StrategyMainList'],
    }),
    // strategy add on (Category)
    strategyAddOnList: builder.query({
      query: ({ page, limit }) => ({
        url: `${apiEndpoints.AUTHORIZED.STRATEGY.StrategyCategory}?page=${page}&limit=${limit}`,
        method: METHODS.GET
      }),
      providesTags: ['StrategyCategory'],
    }),
    addOnStrategy: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STRATEGY.StrategyCategoryAdd,
        method: METHODS.POST,
        body: data
      }),
      invalidatesTags: ['StrategyCategory'],
    }),
    addOnStrategyDelete: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STRATEGY.StrategyCategoryDelete,
        method: METHODS.DELETE,
        body: data
      }),
      invalidatesTags: ['StrategyCategory'],
    }),
    addOnStrategyUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STRATEGY.StrategyCategoryUpdate,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['StrategyCategory'],
    }),
    addOnStrategyStatusUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STRATEGY.StrategyCategoryStatusUpdate,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['StrategyCategory'],
    }),

    // add onStrategy
    addOnStrategyList: builder.query({
      query: ({ page, limit,id }) => ({
        url: `${apiEndpoints.AUTHORIZED.STRATEGY.AddOnStrategyList}?page=${page}&limit=${limit}&strategy_category_id=${id}`,
        method: METHODS.GET
      }),
      providesTags: ['AddOnStrategyList'],
    }),
    addOnStrategyDetail: builder.query({
      query: ({ id }) => ({
        url: `${apiEndpoints.AUTHORIZED.STRATEGY.AddOnStrategyDetail}?strategy_id=${id}`,
        method: METHODS.GET
      }),
    }),
    addOnStrategyListUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STRATEGY.AddOnStrategyUpdate,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['AddOnStrategyList'],
    }),
    addOnStrategyListAdd: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STRATEGY.AddOnStrategyAdd,
        method: METHODS.POST,
        body: data
      }),
      invalidatesTags: ['AddOnStrategyList'],
    }),
    addOnStrategyListDelete: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STRATEGY.AddOnStrategyDelete,
        method: METHODS.DELETE,
        body: data
      }),
      invalidatesTags: ['AddOnStrategyList'],
    }),
    addOnStrategyViewStatusUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.STRATEGY.StrategyUpdateStatus,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['AddOnStrategyList'],
    }),

    // strategyList for sp
    strategyListForSp: builder.query({
      query: ({ entity }) => ({
        url: `${apiEndpoints.AUTHORIZED.COMMON.StrategyList}?strategy_entity=${entity}`,
        method: METHODS.GET
      }),
    }),

    // common content
    commonData: builder.query({
      query: () => ({
        url: `${apiEndpoints.AUTHORIZED.COMMON.CommonContent}`,
        method: METHODS.GET
      }),
      providesTags: ['CommonData'],
    }),
    commonDataUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.COMMON.CommonContentUpdate,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['CommonData'],
    }),
    // internal pathways
    pathwayOverview: builder.query({
      query: ({ rule_type }) => ({
        url: `${apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.Overview}?rule_type=${rule_type}`,
        method: METHODS.GET
      }),
      providesTags: ['internalPathwayOverviews'],
    }),
    pathwayOverviewUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.Overview,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['internalPathwayOverviews'],
    }),
    // hire children
    // work category
    workCategoryHireChild: builder.query({
      query: ({ page, limit }) => ({
        url: `${apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.workCatgoryList}?page=${page}&limit=${limit}`,
        method: METHODS.GET
      }),
      providesTags: ['workCategoryHireChildren'],
    }),
    addWorkCategory: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.workCatgoryAdd,
        method: METHODS.POST,
        body: data
      }),
      invalidatesTags: ['workCategoryHireChildren'],
    }),
    updateWorkCategory: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.workCatgoryUpdate,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['workCategoryHireChildren'],
    }),
    deleteWorkCategory: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.workCatgoryDelete,
        method: METHODS.DELETE,
        body: data
      }),
      invalidatesTags: ['workCategoryHireChildren'],
    }),
    // minor category 
    minorRoleHireChild: builder.query({
      query: ({ page, limit }) => ({
        url: `${apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.minorRoleList}?page=${page}&limit=${limit}`,
        method: METHODS.GET
      }),
      providesTags: ['minorRoles'],
    }),
    addMinorRole: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.minorRoleAdd,
        method: METHODS.POST,
        body: data
      }),
      invalidatesTags: ['minorRoles'],
    }),
    updateMinorRoles: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.minorRoleUpdate,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['minorRoles'],
    }),
    deleteMinorRoles: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.minorRoleDelete,
        method: METHODS.DELETE,
        body: data
      }),
      invalidatesTags: ['minorRoles'],
    }),
    // nil packages
    nilPackageData: builder.query({
      query: ({ page, limit }) => ({
        url: `${apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.nilPackageList}?page=${page}&limit=${limit}`,
        method: METHODS.GET
      }),
      providesTags: ['nilPackageLists'],
    }),
    nilPackageDetail: builder.query({
      query: ({ id }) => ({
        url: `${apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.nilPackageDetail}?package_id=${id}`,
        method: METHODS.GET
      }),
      providesTags: ['nilPackageLists'],
    }),
    updateNILPackage: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.nilPackageUpdate,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['nilPackageLists'],
    }),
    addNILPackage: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.nilPackageAdd,
        method: METHODS.POST,
        body: data
      }),
      invalidatesTags: ['nilPackageLists'],
    }),
    deleteNILPackage: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.nilPackageDelete,
        method: METHODS.DELETE,
        body: data
      }),
      invalidatesTags: ['nilPackageLists'],
    }),
    // dashboard data
    dashboardData: builder.query({
      query: () => ({
        url: `${apiEndpoints.AUTHORIZED.COMMON.DashboadrDataApi}`,
        method: METHODS.GET
      }),
      providesTags: ['dashboadrData'],
    }),
    // client listing
    clientListing: builder.query({
      query: ({ page, limit, type }) => ({
        url: `${apiEndpoints.AUTHORIZED.COMMON.ClientListing}?page=${page}&limit=${limit}&user_type=${type}`,
        method: METHODS.GET
      }),
      providesTags: ['clientListing'],
    }),
    ClientReportList: builder.query({
      query: ({ page, limit, client_id,year }) => ({
        url: `${apiEndpoints.AUTHORIZED.COMMON.ReportList}?page=${page}&limit=${limit}&client_id=${client_id}&year=${year}`,
        method: METHODS.GET
      }),
      providesTags: ['clientReportList'],
    }),
    updateAdvisorSplit: builder.mutation({
      query: (data) => ({
        url: `${apiEndpoints.AUTHORIZED.COMMON.AdvisorSplitApi}`,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['clientListing'],
    }),
    updateClient: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.COMMON.ClientUpdate,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['clientListing'],
    }),
    sendInvite: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.COMMON.InviteSend,
        method: METHODS.POST,
        body: data
      }),
      invalidatesTags: ['clientListing'],
    }),
    // augusta
    augustaCategory: builder.query({
      query: ({ page, limit, type }) => ({
        url: `${apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaCategory}?page=${page}&limit=${limit}&type=${type}`,
        method: METHODS.GET
      }),
      providesTags: ['augustaCategory'],
    }),
    augustaCategoryAdd: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaCategoryAdd,
        method: METHODS.POST,
        body: data
      }),
      invalidatesTags: ['augustaCategory'],
    }),
    augustaCategoryUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaCategoryUpdate,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['augustaCategory'],
    }),
    augustaCategoryDelete: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaCategoryDelete,
        method: METHODS.DELETE,
        body: data
      }),
      invalidatesTags: ['augustaCategory'],
    }),
    // augusat properties
    augustaProperties: builder.query({
      query: ({ page, limit, search }) => ({
        url: `${apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaPropertiesList}?page=${page}&limit=${limit}&search_key=${search}`,
        method: METHODS.GET
      }),
      providesTags: ['augustaProperties'],
    }),
    augustaPropertyFile: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaPropertyImage,
        method: METHODS.POST,
        body: data,
      }),
      invalidatesTags: ['augustaProperties'],
    }),
    augustaPropertyAdd: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaPropertyAdd,
        method: METHODS.POST,
        body: data,
      }),
      invalidatesTags: ['augustaProperties'],
    }),
    augustaPropertyDetail: builder.query({
      query: ({ id }) => ({
        url: `${apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaPropertyDetailApi}?property_id=${id}`,
        method: METHODS.GET
      }),
      providesTags: ['augustaPropertyDetail'],
    }),
    augustaPropertyUpdate: builder.mutation({
      query: (data) => ({
        url: `${apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaPropertyUpdateApi}`,
        method: METHODS.PUT,
        body: data,
      }),
      invalidatesTags: ['augustaPropertyDetail'],
    }),

    augustaPropertyDelete: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaPropertyDeleteApi,
        method: METHODS.DELETE,
        body: data,
      }),
      invalidatesTags: ['augustaProperties'],
    }),
    augustaPropertiesFileUploadZip: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaPropertiesZip,
        method: METHODS.POST,
        body: data,
      }),
      invalidatesTags: ['augustaProperties'],
    }),
    augustaPropertiesFileUploadExcel: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.augustaPropertiesExcel,
        method: METHODS.POST,
        body: data,
      }),
      invalidatesTags: ['augustaProperties'],
    }),
    // r&d
    rAndDCategory: builder.query({
      query: ({ page, limit, type }) => ({
        url: `${apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.rAndCategoryList}?page=${page}&limit=${limit}&type=${type}`,
        method: METHODS.GET
      }),
      providesTags: ['r&dCategory'],
    }),
    rAndDCategoryAdd: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.rAndCategoryAdd,
        method: METHODS.POST,
        body: data
      }),
      invalidatesTags: ['r&dCategory'],
    }),
    rAndDCategoryUpdate: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.rAndCategoryUpdate,
        method: METHODS.PUT,
        body: data
      }),
      invalidatesTags: ['r&dCategory'],
    }),
    rAndDCategoryDelete: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.INTERNALPATHWAYS.rAndCategoryDelete,
        method: METHODS.DELETE,
        body: data
      }),
      invalidatesTags: ['r&dCategory'],
    }),
    // payments
    paymentListing: builder.query({
      query: ({ page, limit,year }) => ({
        url: `${apiEndpoints.AUTHORIZED.COMMON.PaymentsListing}?page=${page}&limit=${limit}&year=${year}`,
        method: METHODS.GET
      }),
      providesTags: ['paymentListing'],
    }),
    paymentDetail: builder.query({
      query: ({advisorId,clientId,year  }) => ({
        url: `${apiEndpoints.AUTHORIZED.COMMON.PaymentDetails}?client_id=${clientId}&advisor_id=${advisorId}&year=${year}`,
        method: METHODS.GET
      }),
      providesTags: ['paymentListing'],
    }),
    // notification
    notificationHit: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.AUTHORIZED.NOTIFICATION.notification,
        method: METHODS.POST,
        body: data
      }),
      invalidatesTags: ['notification'],
    }),



  })
});

export const {
  useLoginMutation,
  useForgotMutation,
  useVerifyMutation,
  useResetMutation,

  useStateBracketAddMutation,
  useStateBracketListMutation,
  useStateBracketUpdateMutation,
  useStateBracketDeleteMutation,

  useFederalBracketListMutation,
  useFederalBracketDeleteMutation,
  useFederalBracketAddMutation,
  useFederalBracketUpdateMutation,

  useStrategyVariableMutation,
  useStrategyVariableUpdateMutation,

  useStrategyVariableFeeListMutation,
  useStrategyVariableFeeUpdateMutation,

  useStrategyVariableDeductionAddMutation,
  useStrategyVariableDeductionListMutation,
  useStrategyVariableDeductionUpdateMutation,

  useStrategyChildAddMutation,
  useStrategyChildListMutation,
  useStrategyChildUpdateMutation,

  useStrategyMainListQuery,
  useStrategyMainViewQuery,
  useStrategyUpdateMutation,
  useStrategyViewStatusUpdateMutation,
  
  useSpFeeListDetailQuery,

  useStrategyAddOnListQuery,
  useAddOnStrategyMutation,
  useAddOnStrategyDeleteMutation,
  useAddOnStrategyUpdateMutation,
  useAddOnStrategyStatusUpdateMutation,

  useAddOnStrategyListQuery,
  useAddOnStrategyDetailQuery,
  useAddOnStrategyListUpdateMutation,
  useAddOnStrategyListAddMutation,
  useAddOnStrategyListDeleteMutation,
  useAddOnStrategyViewStatusUpdateMutation,

  useStrategyListForSpQuery,

  useCommonDataQuery,
  useCommonDataUpdateMutation,

  usePathwayOverviewQuery,
  usePathwayOverviewUpdateMutation,
  useWorkCategoryHireChildQuery,
  useAddWorkCategoryMutation,
  useUpdateWorkCategoryMutation,
  useDeleteWorkCategoryMutation,

  useMinorRoleHireChildQuery,
  useAddMinorRoleMutation,
  useUpdateMinorRolesMutation,
  useDeleteMinorRolesMutation,

  useNilPackageDataQuery,
  useNilPackageDetailQuery,
  useUpdateNILPackageMutation,
  useAddNILPackageMutation,
  useDeleteNILPackageMutation,
  useDashboardDataQuery,

  useClientListingQuery,
  useClientReportListQuery,
  useSendInviteMutation,
  useUpdateClientMutation,
  useUpdateAdvisorSplitMutation,

  useAugustaCategoryQuery,
  useAugustaCategoryAddMutation,
  useAugustaCategoryUpdateMutation,
  useAugustaCategoryDeleteMutation,
  useAugustaPropertiesFileUploadZipMutation,
  useAugustaPropertiesFileUploadExcelMutation,
  useAugustaPropertiesQuery,
  useAugustaPropertyFileMutation,
  useAugustaPropertyAddMutation,
  useAugustaPropertyDetailQuery,
  useAugustaPropertyUpdateMutation,
  useAugustaPropertyDeleteMutation,

  useRAndDCategoryQuery,
  useRAndDCategoryAddMutation,
  useRAndDCategoryUpdateMutation,
  useRAndDCategoryDeleteMutation,

  usePaymentListingQuery,
  usePaymentDetailQuery,
  useNotificationHitMutation
} = apiServices;