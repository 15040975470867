export const apiEndpoints = {
    AUTH: {
        LOGIN: `admin/auth/login`,
        VERIFY_OTP: `admin/auth/verify_otp`,
        FORGOT: `admin/auth/forgot_password`,
        RESET: `admin/auth/reset_password`,
    },
    AUTHORIZED: {
        STATE_BRACKET: {
            StateBracketAdd: `admin/variables/state/bracket/add`,
            StateBracketList: `admin/variables/state/bracket/getlist`,
            StateBracketDelete: `admin/variables/state/bracket/delete`,
            StateBracketUpdate: `admin/variables/state/bracket/update`,
        },
        FEDERAL_BRACKET: {
            FederalBracketAdd: `admin/variables/federal/bracket/add`,
            FederalBracketList: `admin/variables/federal/bracket/getlist`,
            FederalBracketDelete: `admin/variables/federal/bracket/delete`,
            FederalBracketUpdate: `admin/variables/federal/bracket/update`,
        },
        VARIABLE:{
            StrategyVariableList:`admin/variables/getlist`,
            StrategyVariableUpdate:`admin/variables/update`,

            StandardFeeList:`admin/variables/strategy/fees/list`,
            StandardFeeUpdate:`admin/variables/strategy/fees/update`,
            
            StandardDeductionAdd:`admin/variables/standard/deduction/add`,
            StandardDeductionList:`admin/variables/standard/deduction/getlist`,
            StandardDeductionUpdate:`admin/variables/standard/deduction/update`,
            
            StandardChildAdd:`admin/variables/child/deduction/add`,
            StandardChildList:`admin/variables/child/deduction/getlist`,
            StandardChildUpdate:`admin/variables/child/deduction/update`,

            
        },
        STRATEGY: {
            StrategyMainList: `admin/strategy/main/rules/list`,
            StrategyMainDetail: `admin/strategy/main/rules/details`,
            SPFeeList: `admin/strategy/strategy/list`,
            StrategyMainListUpdate: `admin/strategy/main/rules/update`,

            StrategyCategory: `admin/strategy/category/list`,
            StrategyCategoryAdd: `admin/strategy/category/add`,
            StrategyUpdateStatus: `admin/strategy/status/update`,
            StrategyCategoryDelete: `admin/strategy/category/Delete`,
            StrategyCategoryUpdate: `admin/strategy/category/update`,
            StrategyCategoryStatusUpdate: `admin/strategy/category/status/update`,

            AddOnStrategyList:'admin/strategy/addon/list',
            AddOnStrategyDetail:'admin/strategy/addon/details',
            AddOnStrategyUpdate:'admin/strategy/addon/update',
            AddOnStrategyAdd:'admin/strategy/addon/create',
            AddOnStrategyDelete:'admin/strategy/addon/delete',
        },
        COMMON:{
            CommonContent:"common/common_content",
            CommonContentUpdate:"admin/common/common_content",
            CommonMediaUpload:'admin/internal/pathway/upload/media',
            DashboadrDataApi:'admin/user/dashboard',
            ClientListing:'admin/user/list',
            ClientUpdate:'admin/user/status',
            InviteSend:'admin/common/send/invite',
            AdvisorSplitApi:'admin/user/advisor/split',
            PaymentsListing:'admin/common/payment/list',
            PaymentDetails:'admin/common/payment/details',
            ReportList:'admin/user/pathway/report',
            StrategyList:'admin/strategy/strategy/list',
        },
        INTERNALPATHWAYS:{
            Overview:'admin/internal/pathway/overview',
            augustaCategory:'admin/internal/pathway/augusta/category/list',
            augustaCategoryAdd:'admin/internal/pathway/augusta/category/add',
            augustaCategoryUpdate:'admin/internal/pathway/augusta/category/update',
            augustaCategoryDelete:'admin/internal/pathway/augusta/category/delete',
            augustaPropertiesZip:'admin/internal/pathway/augusta/property/upload/images',
            augustaPropertiesExcel:'admin/internal/pathway/augusta/property/upload/excel',
            augustaPropertiesList:'admin/internal/pathway/augusta/property/list',
            augustaPropertyImage:'admin/internal/pathway/upload/augusta/property/media',
            augustaPropertyAdd:'admin/internal/pathway/augusta/property/add',
            augustaPropertyDetailApi:'admin/internal/pathway/augusta/property/details',
            augustaPropertyUpdateApi:'admin/internal/pathway/augusta/property/update',
            augustaPropertyDeleteApi:'admin/internal/pathway/augusta/property/delete',

            rAndCategoryList:'admin/internal/pathway/rnd/list/category',
            rAndCategoryAdd:'admin/internal/pathway/rnd/add/category',
            rAndCategoryUpdate:'admin/internal/pathway/rnd/update/category',
            rAndCategoryDelete:'admin/internal/pathway/rnd/delete/category',

            workCatgoryList:'admin/internal/pathway/hyc/list/work/category',
            workCatgoryAdd:'admin/internal/pathway/hyc/add/work/category',
            workCatgoryUpdate:'/admin/internal/pathway/hyc/update/work/category',
            workCatgoryDelete:'/admin/internal/pathway/hyc/delete/work/category',

            minorRoleList:"admin/internal/pathway/hyc/minor/role/list",
            minorRoleAdd:"admin/internal/pathway/hyc/minor/role/add",
            minorRoleUpdate:"admin/internal/pathway/hyc/minor/role/update",
            minorRoleDelete:"admin/internal/pathway/hyc/minor/role/delete",

            nilPackageList:'admin/internal/pathway/hyc/nil/package/list',
            nilPackageDetail:'admin/internal/pathway/hyc/nil/package/details',
            nilPackageUpdate:'admin/internal/pathway/hyc/nil/package/update',
            nilPackageAdd:'admin/internal/pathway/hyc/nil/package/add',
            nilPackageDelete:'admin/internal/pathway/hyc/nil/package/delete',
        },
        NOTIFICATION:{
            notification:'admin/common/custom/notification'
        }

    },
}