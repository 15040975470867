
export const STATES_WITH_CODES = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District of Columbia',
    'FL': 'Florida',
    'GA': 'Georgia',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'MD': 'Maryland'
};
export const UserType = {
    ADMIN: 1,
    CLIENT: 2,
    ADVISOR: 3,
    STRATEGY_PROVIDER: 4,
};

export const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
}
export const RULE_PAYMENT_STATUS = {
    PAID: 1,
    PENDING: 2,
    CANCEL: 3,
};
export const STRATEGY_TYPE = {
    INTERNAL: 'internal',
    EXTERNAL: 'external',
    ADD_ON:"add_on"
}

export const REFFERAL_FEE_TYPE = {
    PERCENTAGE: 'percentage',
    FLAT: 'flat'
}

export const SP_STRATEGY_STATUS = {
    ACTIVE: 1,
    DELETED: 2,
    DEACTIVATED: 3,
  };

// Convert REFFERAL_FEE_TYPE to an array of key-value pair objects
export const feeOptionsArray = Object.entries(REFFERAL_FEE_TYPE).map(([key, value]) => ({
    key: key,
    value: value
}));

// Notification topics
export const topicArr = [
    {
        label: 'General',
        value: 'general'
    },
    {
        label: 'All SPS',
        value: 'all_sps'
    },
    {
        label: 'All Clients',
        value: 'all_clients'
    },
    {
        label: 'All Advisors',
        value: 'all_advisors'
    },
]